import { BrowserRouter, Route, Routes } from "react-router-dom";
import PortalLayout from "./component/PortalLayout";
import UserDiscounts from "./pages/UserDiscounts";
import EventToken from "./pages/EventToken";
import EventTokenForm from "./forms/EventTokenForm";
import Login from "./pages/Login";
import DiceRoll from "./pages/DiceRoll";
import DiceRollForm from "./forms/DiceRollForm";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route element={<Login  />} path="/login" />
          <Route element={<UserDiscounts />} path="/" />
          <Route element={<EventToken />} path="/event-token" />
          <Route element={<EventTokenForm />} path="/event-token/add" />
          <Route element={<EventTokenForm edit={true} />} path="/event-token/edit" />
          <Route element={<DiceRoll  />} path="/dice-roll" />
          <Route element={<DiceRollForm  />} path="/dice-roll/add" />
          <Route element={<DiceRollForm  edit={true} />} path="/dice-roll/edit" />
        </Routes>
        <Toaster />

      </BrowserRouter>
    </div>
  );
}

export default App;
