import axiosInstance from "./axiosInstance";

const AllServices = {
// roll count api 

  getRollCountsList: async () => {
    try {
      const response = await axiosInstance.get("/rollcounts/getAll");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  CreateRollCountsList: async (data) => {
    try {
      const response = await axiosInstance.post("/rollcounts/create" , data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  EditRollCountsList: async (data) => {
    try {
      const response = await axiosInstance.put("/rollcounts/update" , data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },



  getAllUsersList: async () => {
    try {
      const response = await axiosInstance.get("/users/getAll");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  // event discount routes
  getAllEventToken: async () => {
    try {
      const response = await axiosInstance.get("/eventDiscounts/getAll");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  CreateEventToken: async (data) => {
    try {
      const response = await axiosInstance.post("/eventDiscounts/create" , data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  ActivateEventToken: async (id) => {
    try {
      const response = await axiosInstance.put(`/eventDiscounts/activate/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  DeactivateEventToken: async (id) => {
    try {
      const response = await axiosInstance.put(`/eventDiscounts/deactivate/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  DeleteEventToken: async (id) => {
    try {
      const response = await axiosInstance.delete(`/eventDiscounts/delete/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },


};
export default AllServices;
