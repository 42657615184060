import React, { useEffect, useState } from "react";
import PortalLayout from "../component/PortalLayout";
import { useNavigate } from "react-router-dom";
import { MdDelete, MdEdit } from "react-icons/md";
import DeleteModal from "../component/DeleteModal";
import AllServices from "../services/AllServices";
import { HashLoader } from "react-spinners";
import toast from "react-hot-toast";

 

const DiceRoll = () => {


  const navigation = useNavigate();
  const [DeleteModalShow, setDeleteModalShow] = useState(false);

  const [deleteID, setDeleteID] = useState();

  

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  // const filteredItems = data.filter((item) =>
  //   item.event.toLowerCase().includes(search?.toLowerCase())
  // );

  const deleteRecord = (id) => {
    AllServices.DeleteEventToken(id).then((res) =>
      {
        if (res.success) {
          setData((prevData) => prevData.filter((item) => item.id !== id))
          toast.success('Record Deleted Successfuly')
          setDeleteModalShow(!DeleteModalShow);
        }  

      }

    );
  };

  const handleDelete = (id) => {
    setDeleteModalShow(!DeleteModalShow);
    setDeleteID(id);
  };



  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await AllServices.getRollCountsList();
        console.log('response ' , response);
        setData(response.rollCounts)
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <PortalLayout>
      <div>
      <DeleteModal
          open={DeleteModalShow}
          handleClose={handleDelete}
          ID={deleteID}
          deleteFunction={deleteRecord}
        />     
           <h1 className="text-center text-2xl ">Dice Roll Counts</h1>

        {loading ? (
          <div className="min-h-screen flex justify-center items-center">
            <HashLoader color="#36d7b7" />
          </div>
        ) : (
          <div class="relative overflow-x-auto pt-[5%]">
            <div className="flex justify-between">
              <input
                type="search"
                className="mb-4 px-4 py-[5px] w-[500px] rounded-xl border-2"
                placeholder="Search here"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button
                type="button"
                onClick={() => navigation("add")}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-[5px] me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                Add Dice Roll Count
              </button>
            </div>
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {/* <th scope="col" class="px-6 py-3">
                  id
                </th> */}
                  <th scope="col" class="px-6 py-3">
                    Event
                  </th>
                  <th scope="col" class="px-6 py-3  ">
                    Counts
                  </th>
                  <th scope="col" class="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((value) => (
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    {/* <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Apple MacBook Pro 17"
                  </th> */}
                     <td class="px-6 py-4">{value.event}</td>
                    <td class="px-6 py-4  ">{value.roll_count}</td>
                    <div className="flex gap-2 py-4 ">
                      <MdEdit
                        className="text-xl cursor-pointer"
                        onClick={() => navigation(`edit?id=${value.event}&roll=${value.roll_count}`)}
                      />
                      <MdDelete
                        className="text-xl cursor-pointer"
                        onClick={deleteRecord(value.id)}
                      />
                    </div>
                    {/* <td class="px-6 py-4">$2999</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </PortalLayout>
  );
};

export default DiceRoll;
