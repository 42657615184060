import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { FaDiceD6, FaSignOutAlt } from "react-icons/fa";
import { GiToken } from "react-icons/gi";
import { IoIosSunny } from "react-icons/io";
import { IoSunny } from "react-icons/io5";
import { MdDiscount } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { TiWeatherSunny } from "react-icons/ti";
import { Link, useLocation } from "react-router-dom";

const PortalLayout = ({ children }) => {
  const location = useLocation();

  const [lightDark, setLightDark] = useState(
    localStorage.getItem("lightDark")
      ? localStorage.getItem("lightDark")
      : "light"
  );

  const toggleTheme = () => {
    setLightDark(lightDark === "light" ? "dark" : "light");
  };

  const element = document.documentElement;

  const darkQuery = window.matchMedia("(prefers-color-scheme : dark");

  function onWindowMatch() {
    if (
      localStorage.lightDark === "dark" ||
      (!localStorage.hasOwnProperty("lightDark") && darkQuery.matches)
    ) {
      element.classList.add("dark");
    } else {
      element.classList.remove("dark");
    }
  }
  onWindowMatch();

  useEffect(() => {
    switch (lightDark) {
      case "dark":
        element.classList.add("dark");
        localStorage.setItem("lightDark", "dark");
        break;
      case "light":
        element.classList.remove("dark");
        localStorage.setItem("lightDark", "light");
        break;
      default:
        localStorage.removeItem("lightDark");
        break;
    }
  }, [lightDark]);

  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <div className="bg-gray-50 dark:bg-gray-200 ">
      <div className="bg-gray-50 dark:bg-gray-200 pt-4">
        <RxHamburgerMenu size={23}
          onClick={() => setShowSideBar(!showSideBar)}
          className={`${showSideBar ? "ml-6 " : "ml-72"} cursor-pointer`}
        />
      </div>
      <aside
        id="default-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform  ${
          showSideBar ? "-translate-x-full" : "translate-x-0"
        }  `}
        aria-label="Sidebar"
      >
        <div className="h-full  px-3 py-4 overflow-y-auto bg-gray-200 dark:bg-gray-800 rounded-r-xl">
          <ul className="space-y-2 font-medium h-full mt-auto flex justify-center flex-col">
            <li className="">
              <Link
                to="/"
                className={`${
                  location.pathname === "/"
                    ? "bg-gray-100 dark:bg-gray-700"
                    : ""
                } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
              >
                <MdDiscount size={20} />
                <span className="ms-3 text-md">User Discounts</span>
              </Link>
            </li>
            <li className="">
              <Link
                to="/event-token"
                className={`${
                  location.pathname === "/event-token"
                    ? "bg-gray-100 dark:bg-gray-700"
                    : ""
                } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
              >
                <GiToken size={20} />
                <span className="ms-3 text-md">Event Token</span>
              </Link>
            </li>
            <li className="">
              <Link
                to="/dice-roll"
                className={`${
                  location.pathname === "/dice-roll"
                    ? "bg-gray-100 dark:bg-gray-700"
                    : ""
                } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
              >
                <FaDiceD6 size={20} />
                <span className="ms-3 text-md">Manage Dice Roll</span>
              </Link>
            </li>
            <li className="mt-auto absolute bottom-4">
              <div
                onClick={() => toggleTheme()}
                className={` flex items-center p-2 cursor-pointer text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
              >
                <IoSunny size={20} />
                <span className="ms-3 text-md">Change Theme</span>
              </div>
              <Link
                to="/login "
                className={`${
                  location.pathname === "" ? "bg-gray-100 dark:bg-gray-700" : ""
                } flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
              >
                <FaSignOutAlt size={20} />
                <span className="ms-3 text-md">Sign out</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>

      <div
        className={`p-4  ${
          showSideBar ? "sm:ml-0" : "sm:ml-64"
        } bg-gray-50 dark:bg-gray-200 min-h-screen sm:px-20 `}
      >
        {children}
      </div>
     </div>
  );
};

export default PortalLayout;
