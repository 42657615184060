import React, { useEffect, useState } from "react";
import PortalLayout from "../component/PortalLayout";
import { useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";
import AllServices from "../services/AllServices";
import { MdDelete, MdEdit } from "react-icons/md";
import DeleteModal from "../component/DeleteModal";

const EventToken = () => {
  const navigation = useNavigate();

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [deleteID, setDeleteID] = useState();

  const toggleOpen = () => {
    setOpen(!open);
  };

  const deleteEventToken = (id) => {
    AllServices.DeleteEventToken(id).then((res) =>
      {
        if (res.success) {
          setData((prevData) => prevData.filter((item) => item.id !== id))
        }
      }

    );
    setOpen(!open);
  };

  const handleDelete = (id) => {
    setOpen(!open);
    setDeleteID(id);
  };

  const activateStatus = (id) => {
    AllServices.ActivateEventToken(id).then((res) => {
      console.log("res of activate", res);
      if (res.success) {
        setData((prevData) =>
          prevData.map((item) =>
            item.id === id && item.status === 0 ? { ...item, status: 1 } : item
          )
        );
      }
    });
  };

  const deactivateStatus = (id) => {
    AllServices.DeactivateEventToken(id).then((res) => {
      console.log("res of deactivate", res);
      setData((prevData) =>
        prevData.map((item) =>
          item.id === id && item.status === 1 ? { ...item, status: 0 } : item
        )
      );
    });
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await AllServices.getAllEventToken();
        console.log("response", response);
        setData(response.eventDiscounts);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const filteredItems = data.filter((item) =>
    item.discount_name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <PortalLayout>
      <div>
        <DeleteModal
          open={open}
          handleClose={handleDelete}
          ID={deleteID}
          deleteFunction={deleteEventToken}
        />

        <h1 className="text-center text-2xl ">Event Token List</h1>
        {loading ? (
          <div className="min-h-screen flex justify-center items-center">
            <HashLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="relative overflow-x-auto pt-[5%]">
            <div className="flex justify-between">
              <input
                type="search"
                className="mb-4 px-4 py-[5px] w-[500px] rounded-xl border-2"
                placeholder="Search here"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button
                type="button"
                onClick={() => navigation("add")}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-[5px] me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                Add Event
              </button>
            </div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th className="px-6 py-3 w-[20%]">Event Name</th>
                  <th className="px-6 py-3 w-[20%]">Token</th>
                  <th className="px-6 py-3 w-[20%]">Url</th>
                  <th className="px-6 py-3 w-[20%]">Status</th>
                  {/* <th className="px-6 py-3 w-[20%]"></th> */}
                </tr>
              </thead>
              <tbody>
                {filteredItems.map((value) => (
                  <tr
                    key={value.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {value.discount_name}
                    </th>
                    <td className="px-6 py-4">{value.token}</td>
                    <td className="px-6 py-4">{value.discount_url}</td>
                    <td
                      className="px-6 py-4 flex items-center cursor-pointer"
                      onClick={() => {
                        if (value.status === 0) {
                          activateStatus(value.id);
                        } else if (value.status === 1) {
                          deactivateStatus(value.id);
                        }
                      }}
                    >
                      <div
                        className={`${
                          value.status === 1
                            ? "border-2 border-green-600 rounded-full"
                            : "border-2 border-red-600 rounded-full"
                        } h-2 w-2`}
                      ></div>
                      <p className="ml-2">
                        {value.status === 0 ? "Disable" : "Active"}
                      </p>
                    </td>
                    <td className="px-6 flex gap-2 justify-end mt-[-2rem]">
                      <MdDelete
                        className="text-xl cursor-pointer"
                        onClick={() => handleDelete(value.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </PortalLayout>
  );
};

export default EventToken;
