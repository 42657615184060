import React, { useEffect, useState } from "react";
import PortalLayout from "../component/PortalLayout";
import AllServices from "../services/AllServices";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const EventTokenForm = ({ edit = false }) => {
  const navigate = useNavigate();

  const [event , setEvent] = useState('')

  const [encoded, setEncoded] = useState(window.btoa(event));
 

  const [formData, setFormData] = useState({
    discount_name: "",
    discount_url: '',
    token: '',
  });

  useEffect(() => {
    setEncoded(window.btoa(event));
  }, [event]);

   

  useEffect(() => {
    setFormData({...formData ,token: encoded  , discount_url: `https://develons/shopify-store/${encoded}`})
  },[encoded])

  const handleSubmit = (e) => {
    e.preventDefault();
    AllServices.CreateEventToken(formData).then((res) => {
      if (res.responseCode === 200) {
        toast.success("Event Created Successfully");
        navigate("/event-token");
      }
    });
  };

  return (
    <PortalLayout>
      <h1 className="text-center text-2xl ">Add Event Token</h1>

      <form onSubmit={handleSubmit} className=" w-[100%] mx-auto pt-[10%]  ">
        <div className="relative z-0 w-full mb-5 group ">
          <input
            type="text"
            name="floating_token"
            id="floating_token"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            value={formData.discount_name}
            onChange={(e) =>
              {setFormData({ ...formData, discount_name: e.target.value }) ; setEvent(e.target.value) }
            }
            required
          />
          <label
            for="floating_token"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Event Name
          </label>
        </div>
        
        <button
          type="submit"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Add Event Token
        </button>
      </form>
    </PortalLayout>
  );
};

export default EventTokenForm;
