import React, { useEffect, useState } from "react";
import PortalLayout from "../component/PortalLayout";
import AllServices from "../services/AllServices";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { HashLoader } from "react-spinners";

const DiceRollForm = ({ edit = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = new URLSearchParams(location.search);

  const [formData, setFormData] = useState({
    event: params.has("id") ? params.get("id") : "",
    roll_count: params.has("roll") ? params.get("roll") : "",
  });

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await AllServices.getAllEventToken();
        console.log("response", response);
        setData(response.eventDiscounts);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (edit) {
      AllServices.EditRollCountsList(formData).then((res) => {
        if (res.status) {
          toast.success("Dice Roll Count Edit Successfully");
          navigate("/dice-roll");
        } else {
          toast.error('unexpected error occured')
        }
      });
    } else {
      AllServices.CreateRollCountsList(formData).then((res) => {

        if (res.responseCode === 200) {
          toast.success("Dice Roll Count Add Successfully");
          navigate("/dice-roll");
        } else {
          toast.error('unexpected error occured')
        }
      });
    }
  };

  return (
    <PortalLayout>
      <h1 className="text-center text-2xl ">
        {" "}
        {`${edit ? "Update" : "Add"} Dice Roll`}
      </h1>
      {loading ? (
        <div className="min-h-screen flex justify-center items-center">
          <HashLoader color="#36d7b7" />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className=" w-[80%] mx-auto pt-[10%]  ">
          <div className="max-md:flex-col flex gap-6 mb-6 ">
            <div className="relative z-0 w-full group">
              <select
                name=""
                id="floating_select"
                value={formData.event}
                onChange={(e) =>
                  setFormData({ ...formData, event: e.target.value })
                }
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              >
                <option selected>Select Event</option>
                {data.map((value) => (
                  <option key={value.id} value={value.id}>
                    {value.discount_name}
                  </option>
                ))}
              </select>
              <label
                htmlFor="floating_select"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Select Event
              </label>
            </div>

            <div className="relative z-0 w-full  group ">
              <input
                type="number"
                name="floating_token"
                id="floating_token"
                value={formData.roll_count}
                onChange={(e) =>
                  setFormData({ ...formData, roll_count: e.target.value })
                }
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                for="floating_token"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Roll Dice Count
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {`${edit ? "Update" : "Add"} Dice Roll`}
          </button>
        </form>
      )}
    </PortalLayout>
  );
};

export default DiceRollForm;
