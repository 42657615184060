import React, { useEffect, useState } from "react";
import PortalLayout from "../component/PortalLayout";
import { useNavigate } from "react-router-dom";
import AllServices from "../services/AllServices";
import { HashLoader } from "react-spinners";
import moment from "moment";

// const data = [
//   { id: 1, email: "", phone: "032154", discount: 2 },
//   { id: 2, email: "test@email.com", phone: "", discount: 5 },
//   { id: 3, email: "", phone: "032154", discount: 10 },
//   { id: 4, email: "test2@email.com", phone: "", discount: 20 },
//   { id: 5, email: "", phone: "032154", discount: 19 },
// ];

const UserDiscounts = () => {
  const navigation = useNavigate();

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const filteredItems = data?.filter(
    (item) =>
      item.email?.toLowerCase().includes(search.toLowerCase()) ||
      item.phone?.toLowerCase().includes(search.toLowerCase()) ||
      item.discount?.toLowerCase().includes(search.toLowerCase()) 
  );

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await AllServices.getAllUsersList();
        console.table('response' , response.eventDiscounts);
        setData(response.eventDiscounts)
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <PortalLayout>
      <div>
        <h1 className="text-center text-2xl ">Discounts List</h1>
        {loading ? (
          <div className="min-h-screen flex justify-center items-center">
            <HashLoader color="#36d7b7" />
          </div>
        ) : (
        <div class="relative overflow-x-auto pt-[5%]">
          <input
            type="search"
            className="mb-4 px-4 py-[5px] w-[500px] rounded-xl border-2"
            placeholder="Search here"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {/* <th scope="col" class="px-6 py-3">
                  id
                </th> */}
                <th scope="col" class="px-6 py-3">
                  Email / Phone
                </th>
                <th scope="col" class="px-6 py-3">
                  Date / Time
                </th>
                <th scope="col" class="px-6 py-3 text-right">
                  Discount
                </th>
                {/* <th scope="col" class="px-6 py-3">
                  Price
                </th> */}
              </tr>
            </thead>
            <tbody>
              {filteredItems.map((value) => (
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  {/* <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Apple MacBook Pro 17"
                  </th> */}
                  {value.email && <td class="px-6 py-4">{value.email}</td>}
                  {value.phone && <td class="px-6 py-4">{value.phone}</td>}
                  <td class="px-6 py-4 ">{moment(value.created).format('MMMM Do YYYY, h:mm:ss a')} </td>
                  <td class="px-6 py-4 text-right">{value.discount} %</td>
                  {/* <td class="px-6 py-4">$2999</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        )}
      </div>
    </PortalLayout>
  );
};

export default UserDiscounts;
